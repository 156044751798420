import React from "react";
import NavLink from "../../../NavLink";

const NavBar = ({ list }) => {
  return (
    <nav className="">
      <ul className="flex items-center">
        {list?.map(({ parent, child }, index) => (
          <li
            key={index}
            className="flex items-center relative group pr-[20px] xl:pr-[50px] transition-colors duration-300 hover:text-primary text-sm lg:text-lg 2xl:text-[20px] font-normal 2xl:leading-[30px]"
          >
            <NavLink
              type={parent?.type}
              link={parent?.link}
              label={parent?.label}
            />
            {child?.length > 0 && (
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 2xl:ml-[19px]"
              >
                <path
                  d="M9 1L5 5L1 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {child?.length > 0 && (
              <ul className="absolute transition-all rounded-xl -translate-x-6 invisible opacity-0 group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 left-0 top-6 2xl:top-7 bg-[#f6fcfa] py-2 px-5 w-[180px] shadow-[rgba(0,0,0,0.08)_0_1px_2px_0]">
                {child.map((child, ind) => {
                  return (
                    <li
                      key={ind}
                      className="relative flex items-center text-sm 2xl:text-base leading-6 2xl:leading-[30px] font-normal border-b last:border-b-0 border-b-[#eee] py-2"
                    >
                      <NavLink
                        type={child.type}
                        link={child.link}
                        label={child.label}
                        cls="text-black transition-all duration-300 cursor-pointer hover:translate-x-2 hover:text-primary"
                      />
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
