import React from "react";
import { Link } from "gatsby";

export default function NavLink({
  type,
  link,
  label,
  cls = "",
  onClick = null,
}) {
  if (type === "internal")
    return (
      <Link to={link} onClick={onClick}>
        {" "}
        <span className={`menu-text ${cls}`}>{label}</span>
      </Link>
    );
  else
    return (
      <a href={link} target="_blank" onClick={onClick}>
        <span className={`menu-text ${cls}`}>{label}</span>
      </a>
    );
}
