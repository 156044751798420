import React, { Fragment, useState, useEffect } from "react";
import Logo from "../logo/Logo";
import NavBar from "./NavBar/NavBar";

const DeskHeader = ({ data }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const header = document.querySelector(".header-section");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <Fragment>
      <div
        className={`header-section bg-transparent sticky-header section ${
          scroll > headerTop ? "is-sticky" : ""
        }`}
      >
        <div className="header-inner px-[50px] 2xl:px-[82px] pt-[30px] 2xl:pt-[48px]">
          <div className="cc-container relative">
            <div className="flex items-center">
              <div>
                <Logo image={data?.logo} />
              </div>
              <div className="ml-[80px] 2xl:ml-[121px]">
                <NavBar list={data?.menu} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DeskHeader;
