import React from "react";
import Layout from "./src/components/layout";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Toaster } from "react-hot-toast";

// import "aos/dist/aos.css";
// import "react-modal-video/scss/modal-video.scss";
import "./src/styles/global.scss";

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>
      {element} <Toaster />
    </Layout>
  );
};
