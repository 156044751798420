import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const Logo = ({ image }) => {
  return (
    <div className="w-[150px] md:w-[180px] h-[50px] md:h-[61px] relative logo">
      <Link to={"/"}>
        {/* <StaticImage src="../../../images/logo2.jpeg" /> */}
        <GatsbyImage image={image.asset?.gatsbyImageData} alt={image.alt} />
        {/* <img
          className=""
          src={`${image?.asset.url}?auto=format`}
          alt={image?.alt}
          // height={150}
          // width={250}
        /> */}
      </Link>
    </div>
  );
};

export default Logo;
