import React, { Fragment, useState, useEffect } from "react";
import Logo from "../logo/Logo";
import NavLink from "../../NavLink";

const NavHeader = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(1000);

  useEffect(() => {
    if (open) {
      const menuDiv = document.getElementById("inner");
      menuDiv.style.transform = "translateX(0px)";
    } else {
      const menuDiv = document.getElementById("inner");
      menuDiv.style.transform = "translateX(-800px)";
    }
  }, [open]);

  const toggleChild = (index) => {
    if (index === selected) {
      setSelected(1000);
    } else {
      setSelected(index);
    }
  };

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <div className={`mobile-header`}>
        <div className="flex items-center justify-between px-4 py-7">
          <div className="flex -mt-6">
            <Logo image={data?.logo} />
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            fill="black"
            viewBox="0 0 16 16"
            onClick={() => setOpen(true)}
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </div>

        <div
          className={`mobile-menu transition-all duration-400 ${
            open ? "visible" : "invisible"
          }`}
        >
          <div className="inner" id="inner">
            <div className="flex items-center justify-between px-4 py-2">
              <div className="flex items-center">
                <Logo image={data?.logo} />
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                fill="black"
                viewBox="0 0 16 16"
                onClick={() => setOpen(false)}
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </div>
            <ul className="pl-7 pt-8">
              {data?.menu?.map(({ parent, child }, index) => {
                return (
                  <li
                    key={index}
                    className={`relative text-secondary my-3  transition-all overflow-hidden duration-500 text-base 2xl:text-[20px] leading-6 2xl:leading-[30px] font-normal ${
                      selected === index ? "max-h-[500px]" : "max-h-[25px]"
                    }`}
                  >
                    <div className="flex items-center">
                      <NavLink
                        type={parent.type}
                        link={parent.link}
                        label={parent.label}
                        cls="cursor-pointer"
                      />
                      {child?.length > 0 && (
                        <svg
                          width="10"
                          height="6"
                          viewBox="0 0 10 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={() => toggleChild(index)}
                          className={`ml-1 2xl:ml-[19px] w-[50px] bg-transparent transition-transform ${
                            selected === index ? "rotate-180" : "rotate-0"
                          }  cursor-pointer`}
                        >
                          <path
                            d="M9 1L5 5L1 1"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                    {child?.length > 0 && (
                      <ul className="ml-2 transition-all">
                        {child.map((child, ind) => {
                          return (
                            <li
                              key={ind}
                              className="relative flex items-center text-sm 2xl:text-base leading-6 2xl:leading-[30px] font-normal"
                            >
                              <NavLink
                                type={child.type}
                                link={child.link}
                                label={child.label}
                                cls="cursor-pointer"
                                onClick={toggleMenu}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavHeader;
