import React from "react";
import Logo from "./logo/Logo";
import FooterLinkItem from "./Footer/FooterLinkItem.jsx";
import { PortableText } from "@portabletext/react";

const Footer = ({ data }) => {
  return (
    <div className="footer p-4 sm:p-10">
      <div className="cc-container">
        <div className="flex flex-col lg:flex-row mb-6 md:mb-[100px]">
          <div className="">
            <div className="">
              <div className="">
                <Logo image={data?.logo} />
              </div>

              <div className="mt-8">
                {data?.contact.map((item, index) =>
                  item.inputType === "link" ? (
                    <p
                      key={index}
                      className="text-secondary hover:text-primary py-1"
                    >
                      <a href={item.link}>{item.value}</a>
                    </p>
                  ) : (
                    <p key={index} className="text-secondary py-1">
                      {item.value}
                    </p>
                  )
                )}
              </div>
              <div className="flex items-center space-x-4 mt-3 ml-2">
                {data?.social.map((item, index) => (
                  <a
                    href={item?.url}
                    key={index}
                    aria-label="link"
                    target="_blank"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.icon }}
                      className="flex justify-center h-5 w-5"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {data?.menu &&
            data.menu.map((single, key) => {
              return (
                <div
                  key={key}
                  className="w-full lg:ml-[70px] xl:ml-[100px] mt-6"
                >
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
        </div>

        <div className="">
          <div className="border-t-2 border-t-assent py-4">
            <div className="text-secondary text-center">
              {data?._rawCc && <PortableText value={data?._rawCc} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
