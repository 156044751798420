import PropTypes from "prop-types";
import React from "react";
import NavLink from "../../NavLink";

const FooterLinkItem = ({ data }) => {
  return (
    <>
      <h4 className="font-bold text-base">{data?.heading}</h4>
      <div className="">
        <ul>
          {data?.menu?.map((single, key) => (
            <li key={key} className="py-1">
              <NavLink
                type={single.type}
                link={single.link}
                label={single.label}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

FooterLinkItem.propTypes = {
  data: PropTypes.object,
};

export default FooterLinkItem;
