import React from "react";
import NavDesktop from "./DesktopNav";
import NavMobile from "./MobileNav";
import { useWindowSize } from "../../../hooks/windowSize";

export default function Navbar({ data }) {
  const { width } = useWindowSize();

  if (width === 0) return <></>;
  return width > 769 ? <NavDesktop data={data} /> : <NavMobile data={data} />;
}
