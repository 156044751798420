import React from "react";
import { useStaticQuery, graphql, Script } from "gatsby";
import Header from "./header";
import Footer from "./Footer";
// import ScrollToTop from "../ScrollToTop";

// const OPTIONS = {
//   threshold: 0,
//   rootMargin: "0px 0px -250px 0px",
// };

export default function Layout({ children }) {
  // useEffect(() => {
  // const AOS = require("aos");
  // AOS.init({
  //   once: true,
  // });
  // }, []);
  // Intersection observer
  // useEffect(() => {
  //   console.log('==UseEffect====');
  //   const sliders = document.querySelectorAll(".fadeIn");

  //   const appearOnScroll = new IntersectionObserver(function (
  //     entries,
  //     appearOnScroll
  //   ) {
  //     entries.forEach((entry) => {
  //       if (!entry.isIntersecting) {
  //         return;
  //       } else {
  //         entry.target.classList.add("start");
  //         appearOnScroll.unobserve(entry.target);
  //       }
  //     });
  //   },
  //   OPTIONS);
  //   sliders.forEach((slider) => {
  //     appearOnScroll.observe(slider);
  //   });
  // }, []);

  const { sanitySitesettings } = useStaticQuery(
    graphql`
      query {
        sanitySitesettings {
          header {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            menu {
              parent {
                label
                link
                type
              }
              child {
                label
                link
                type
              }
            }
          }
          footer {
            logo {
              alt
              asset {
                url
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
              }
            }
            menu {
              heading
              menu {
                label
                link
                type
              }
            }
            social {
              icon
              name
              url
            }
            contact {
              value
              link
              inputType
            }
            _rawCc
          }
        }
      }
    `
  );

  return (
    <>
      <Header data={sanitySitesettings?.header} />
      <main className="overflow-x-hidden">{children}</main>
      {/* <ScrollToTop color={sanitySitesettings?.scroll} /> */}

      <Footer data={sanitySitesettings?.footer} />
      <Script
        id="clarity-script"
        strategy="post-hydrate"
        // type="text/javascript"
      >
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "p0jxd1efvs");`}
      </Script>
    </>
  );
}
